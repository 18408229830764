import { createWebHistory, createRouter } from "vue-router";
import Home from "../components/MainPage";

import store from "../store"
import { routeGuard } from "../service/auth0";



const routes = [
    {
        path: "/",
        component: Home,
        meta: {
            title: 'Kibanova Dolls - студия авторской куклы'
        }
    },
    {
        path: "/politics",
        component: () => import('@/components/Politics'),
    },
    {
        path: "/oferta",
        component: () => import('@/components/Oferta'),
    },
    {
        path: "/payment",
        component: () => import('@/components/Payment'),
    },
    {
        path: "/item/:id",
        component: () => import('@/components/ItemPayment'),
        beforeEnter: routeGuard
    },
    {
        path: "/order/:id",
        component: () => import('@/components/OrderPayment'),
        beforeEnter: routeGuard
    },
    {
        path: "/404",
        component: () => import('@/components/Errors/Page404'),
    },
    {
        path: "/403",
        component: () => import('@/components/Errors/Page403'),

    },
    {
        path: "/error_mail",
        component: () => import('@/components/Errors/PageErrorEmail'),

    },
    {
        path: "/error_mail_instructions",
        component: () => import('@/components/Errors/ErrorMailInstruction'),

    },
    {
        path: '/newyear',
        component: () => import('@/components/landings/NewYear1/MainScreen.vue'),
    },
    {
        path: '/beginners',
        component: () => import('@/components/landings/BegCourse/BegCourse.vue'),
    },
    {
        path: '/marchbook',
        component: () => import('@/components/landings/March/MarchBook.vue'),
    },
    {
        path: '/book',
        component: () => import('@/components/landings/March/MarchBook.vue'),
    },
    {
        path: '/ani',
        component: () => import('@/components/landings/March/animation.vue'),
    },
    {
        path: '/bjd',
        component: () => import('@/components/landings/March/MarchBJD.vue'),
    },
    {
        path: '/bjd2',
        component: () => import('@/components/landings/March/MayBJD.vue'),
    },
    {
        path: '/new',
        component: () => import('@/components/MainNew.vue'),
    },
    {
        path: '/dollchiki',
        component: () => import('@/components/DollChiki.vue'),
    },
    {
        path: '/demo/bjd',
        component: () => import('@/components/demo/DemoBjd.vue'),
    },
    {
        path: '/facemistakes',
        component: () => import('@/components/landings/FaceMistakes/FaceMistakes.vue'),
    },
    {
        path: '/facecheck',
        component: () => import('@/components/landings/FaceMistakes/FaceCheckList.vue'),
    },
    {
        path: '/facecheck2',
        component: () => import('@/components/landings/FaceMistakes/FaceCheckList2.vue'),
    },
    {
        path: '/facecheck3',
        component: () => import('@/components/landings/FaceMistakes/FaceCheckList3.vue'),
    },
    {
        path: '/facecheck4',
        component: () => import('@/components/landings/FaceMistakes/FaceCheckList4.vue'),
    },
    {
        path: '/insta',
        component: () => import('@/components/landings/Tapink.vue'),
    },
    {
        path: '/inst',
        component: () => import('@/components/landings/Tapink.vue'),
    },
    {
        path: '/inst_link',
        component: () => import('@/components/landings/Tapink.vue'),
    },
    {
        path: '/inst_link_3',
        component: () => import('@/components/landings/Tapink.vue'),
    },
    {
        path: '/inst_link_4',
        component: () => import('@/components/landings/Tapink.vue'),
    },
    {
        path: '/inst_link_5',
        component: () => import('@/components/landings/Tapink.vue'),
    },
    {
        path: '/insta6',
        component: () => import('@/components/landings/TaplinkNew.vue'),
    },

    {
        path: '/mycourses/body/:id',
        component:  () => import('@/components/Membership/MBCourseContainer'),
        props: {
            pathSegment: "AnatomyBody/MBCourseBody",
            includeId: true
        },
        beforeEnter: routeGuard
    },
    {
        path: '/mycourses/draw/:id',
        component:  () => import('@/components/Membership/MBCourseContainer'),
        props: {
            pathSegment: "Draw/MBCourseDraw",
            includeId: true
        },
        beforeEnter: routeGuard
    },
    {
        path: '/mycourses/costume/:id',
        component:  () => import('@/components/Membership/MBCourseContainer'),
        props: {
            pathSegment: "Costume/MBCourseCostume",
            includeId: true
        },
        beforeEnter: routeGuard
    },
    {
        path: '/mycourses/beginner2/:id',
        component:  () => import('@/components/Membership/MBCourseContainer'),
        props: {
            pathSegment: "Beginner2/MBCourseBeginner",
            includeId: true
        },
        beforeEnter: routeGuard
    },
    {
        path: '/mycourses/emotions/:id',
        component:  () => import('@/components/Membership/MBCourseContainer'),
        props: {
            pathSegment: "Emotions/MBCourseEmotions",
            includeId: true
        },
        beforeEnter: routeGuard
    },
    {
        path: '/mycourses/head/:id',
        component:  () => import('@/components/Membership/MBCourseContainer'),
        props: {
            pathSegment: "AnatomyHead/MBCourseHead",
            includeId: true
        },
        beforeEnter: routeGuard
    },
    {
        path: '/mycourses/Bjd/:id',
        component:  () => import('@/components/Membership/MBCourseContainer'),
        props: {
            pathSegment: "Bjd/Bjd",
            includeId: false
        },
        beforeEnter: routeGuard
    },
    {
        path: '/mycourses/beginner/:id',
        component:  () => import('@/components/Membership/MBCourseContainer'),
        props: {
            pathSegment: "BeginnersBook/Book",
            includeId: false
        },
        beforeEnter: routeGuard
    },
    {
        path: '/mycourses/facemistakes/:id',
        component:  () => import('@/components/Membership/MBCourseContainer'),
        props: {
            pathSegment: "Free/FaceMistakes/FaceMistakes",
            includeId: true
        },
        beforeEnter: routeGuard
    },
    {
        path: '/mycourses/face-emotions/:id',
        component:  () => import('@/components/Membership/MBCourseContainer'),
        props: {
            pathSegment: "Free/FaceEmotions/FaceEmotions",
            includeId: true
        },
        beforeEnter: routeGuard
    },
    {
        path: '/mycourses/facechecklist',
        redirect: '/mycourses/face-emotions/1'
    },
    {
        path: '/mycourses/facechecklist-s',
        redirect: '/mycourses/face-emotions/2'
    },
    {
        path: '/mycourses/bodoir-materials',
        component:  () => import('@/components/Membership/Free/BodoirMaterials.vue'),
        beforeEnter: routeGuard
    },
    {
        path: '/mycourses/bjd-materials',
        component:  () => import('@/components/Membership/Free/BJDMaterials.vue'),
        beforeEnter: routeGuard
    },
    {
        path: '/mycourses/homework',
        component:  () => import('@/components/Membership/Free/Homework.vue'),
        beforeEnter: routeGuard
    },{
        path: '/mycourses/payments',
        component:  () => import('@/components/Membership/MBUserPayments.vue'),
        beforeEnter: routeGuard
    },
    {
        path: '/mycourses/childhead/:id',
        component:  () => import('@/components/Membership/MBCourseContainer'),
        props: {
            pathSegment: "ChildHead/MBChildHead",
            includeId: true
        },
        beforeEnter: routeGuard
    },
    {
        path: '/mycourses',
        component:  () => import('@/components/Membership/MBCourse'),
        beforeEnter: routeGuard
    },
    {  //ВНИМАНИЕ! этот путь должен быть последним
        path: '/:pathMatch(.*)*',
        component: () => import('@/components/Errors/Page404'),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes
});
router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0)
    if (to.path.startsWith('/item/') ) {
        let id = to.params.id
        store.state.goods.filter(item => item.id == id).length > 0 ? next() : next('/404')
    } else if (to.path === "/blackfriday") {
        next('/')
    }  else if (to.path === "/newyear") {
        next('/')
    }  else if (to.path === "/marchbook") {
        next('/book')
    } else {
        next();
    }
})
export default router;
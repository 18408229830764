<template>
  <router-link :class='$mq' to="/">
    <div class="logo" :class='$mq'>
      <KILogoNew width="104px" class="logo-img"/>
      <p>{{ $t("label.businessName") }} <br/> {{ $t("label.name") }}</p>
    </div>
  </router-link>
</template>

<script>
import KILogoNew from "@/components/Widgets/Icons/KILogoNew";

export default {
  name: "KLogo",
  components: {
    KILogoNew,
  },
  i18n: {
    messages: {
      en: {
        label: {
          businessName: "Dolls studio by",
          name: 'Kibanova Luda'
        }
      },
      ru: {
        label: {
          businessName: "Студия авторской куклы",
          name: 'Людмилы Кибановой'
        }
      }
    },
  }
}
</script>

<style scoped>
.logo {
  display: flex;
  align-items: flex-end;
  font-size: 0.8em;
  line-height: 1.3em;
  color: #482431;
}
.logo.mobile { 
  max-width: 12em;
}

.logo p {
  margin-left: 2em;
  padding-left: 2em;
  border-left: 1px solid #48243144;
}
  .logo.halftablet p, .logo.mobile p{
    display: none;
  }

.logo:hover {
  transform: scale(1.05);
  transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);

}


</style>
<template>
    <div class="container items">
        <div :class="['BFcont-block', $mq, good.count == 2 ? 'full': '']" v-for="(good) in getGoods" :key="good.id">
            <div v-if="good.count == 0" class="BFitem-cont" >
                
                <div class="BFitem-top">
                    <KImage :src="getImage(good.id)" @click="playVideo(good.benefits[0])" imgClass='BFitem-img' alt=""/>
                    <div class="BFitem-text :class='$mq'" :class='$mq' @click="playVideo(good.benefits[0])">
                        <h2><span class='BFitem-h' v-html="good.name"></span></h2>
                        <p>{{good.desc}}</p>
                    </div>
                </div>
                <div class="BFitem-price" :class='$mq'>
                    <p class='pnew'>{{good.priceorig}}&#8381;</p>

                    <KBuyButton :good-id="good.id"/>
                </div>
            </div>

            <div  v-if="good.count == 2" class="BFcombo-cont" :class='$mq'>
                <KImage :src="require('@/assets/blackfriday/BF-present.png')" imgClass='BFcombo-present' alt=""/>
                <div class="BFcombo-elem" :class='$mq'>
                    <h3>{{good.benefits[0]}}</h3>
                    <p>{{good.benefits[1]}}&#8381;</p>
                </div>

                <p>+</p>

                <div class="BFcombo-elem" :class='$mq'>
                    <h3>{{good.benefits[2]}}</h3>
                    <p>{{good.benefits[3]}}&#8381;</p>
                </div>

                <p>=</p>

                <div class="BFcombo-elem" :class='$mq'>
                    <p class='absolute'>{{good.benefits[4]}}&#8381;</p>
                    <h3 class='big'>{{good.benefits[5]}}&#8381;</h3>
                    <KBuyButton extra-class="combo" :good-id="good.id" />
                </div>           

                <div class="BFcombo-balls" :class='$mq'>
                    <KImage :src="require('@/assets/blackfriday/BF-balls.png')" imgClass='BFcombo-ball' alt=""/>
                </div>
            </div>
        </div>    
    </div>
</template>

<script>
    import KImage from "../Widgets/KImage";
    import KBuyButton from "../Widgets/KBuyButton";
    export default {
        components: {
            KImage,
            KBuyButton
        },
        props: {
            items: Array
        },
        methods: {
            getDiscount (good){
                return 100 - good.price / good.priceorig * 100
            },

            getImage (id){
                if (id == 4) {
                    return require('@/assets/main/anatomy-head.png')
                }
                if (id == 5) {
                    return require('@/assets/main/anatomy-body.png')
                }
                if (id == 7) {
                    return require('@/assets/main/drawing.png')
                }
                if (id == 8) {
                    return require('@/assets/main/costume.png')
                }
            },

            playVideo(videoId) {
                let videoWidth = window.innerWidth < 800 ? window.innerWidth : 800
                let videoHeight = videoWidth / 1.77
                this.$swal.fire({
                    width: videoWidth+'px',
                    background: '#eae2e0',
                    html: "<iframe frameborder=\"0\" allowfullscreen=\"1\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" title=\"YouTube video player\" width=\""+videoWidth+"\" height=\""+videoHeight+"\" src=\"https://www.youtube.com/embed/"+videoId+"?autoplay=1&controls=0&rel=0&modestbranding=0&loop=1&enablejsapi=1&origin=https%3A%2F%2Fkibanovadolls.ru&widgetid=1\"></iframe>",
                    showCloseButton: true,
                    showConfirmButton: false,
                    // closeButtonHtml: ""
                })
            }
        },
        computed: {
            getGoods () {
                let goods = this.$store.state.goods.filter (good => {
                    return this.items.includes(good.id)
                } )
                return goods
            },
            
        },
    }
</script>

<style>
    .BFcont-block{
        width: 50%;
        margin-top: 80px;
    }
    .BFcont-block.mobile, .BFcont-block.halftablet {
        width: 100%;
        margin-top: 60px;
    }

    .container.items {
        max-width: 1000px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .container.items .BFcont-block.mobile {
        margin-bottom: 3em;
    }

    .container.items .BFcont-block {
         display: block;
         cursor: pointer;
         transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
     }

        .container.items .BFcont-block:hover {
            transform: scale(1.05);
        }

     .container.items .BFitem-text h2{
         text-align: center;
     }

    .container.items .BFitem-text h2 .BFitem-h,  .container.items .BFitem-text h2 .BFitem-h span {
        color: white;
        position: relative;
        text-align: right;
    }
    .container.items .BFitem-text h2 .BFitem-h span {
        font-family: 'TheArtist', sans-serif;
        right: -20px;
        bottom: 0px;
        position: relative;
        right: -90px;
    }
    
    .container.items .BFcont-block {
        margin-top: 0;
        margin-bottom: 80px;
    }

    .container.items .BFitem-text p {
        margin-bottom: 0;
    }

    .container.items .BFcombo-ball {
        max-width: 180px;
    }
        .container.items .BFcombo-balls.mobile .BFcombo-ball {
        max-width: 100px;
    }

    .container.items .BFcombo-balls {
        bottom: -70px;
    }

    .container.items .BFcombo-present {
         bottom: -100px;
         left: -100px;
     }

     .container.items {
         right: 0;
         left: 0;
         margin: 0 auto;
        }

        .container.items .BFcombo-cont {
            margin:0;
        }
            .container.items .BFcombo-cont.mobile {
                margin-bottom: 50pt;
                margin-top: 0pt;
            }
    .BFitem-cont{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }
    .BFitem-img {
        max-width: 100%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        align-self: center;
    }

    .BFitem-text {
        margin: -30px auto 0 auto;
        position: relative;
        z-index: 3;
        width: 80%;
    }
    .BFitem-text.mobile {
        width: 90%;
    }

    .BFitem-text h2 {
        margin-bottom: -40px;
        margin-top:0;
        line-height: 20pt;
    }
        .BFitem-text.mobile h2 {
            margin-bottom: -1.5em;
        }


    .BFitem-text h2 .BFitem-h {
        font: 40pt/40pt 'Kudryashev', sans-serif;
        text-align: center;
        margin-top: 130px;
        color: white;
        left:0;
        top:0;
    }
    .BFitem-text.mobile h2 .BFitem-h{
        font-size: 36pt;
        line-height: 36pt;
    }

    .BFitem-text h2 .BFitem-h span {
        color: white;
        right: -90px;
        top: -20px;
    }

    .BFitem-text.mobile h2 .BFitem-h span{
        font-size: 40pt;
        right: -70px;
    }

    .BFitem-text p {
        line-height: 17pt;
        font-size: 12pt;
        margin-bottom: 20px;;
    }

        .container.items .BFitem-text.mobile p {
            font-size: 0.8em;
            line-height: 1.5em;
            margin-bottom: 1em;
        }

    /* PRICE SECTION*/

    .BFitem-price {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;
        width: 80%;
        line-height: 25pt;
        margin-top: 1em;
    }
    .BFitem-price.mobile{
        width: 90%;
        position: relative;
        top: -10pt;        
    }

    .BFitem-price .old{
        color: #d98c6b;
        font-size: 22pt;
        text-decoration: line-through;
        width: 35%;
    }

    .BFitem-price .pnew {
        font-size: 24pt;
        width: 35%;
    }
    .BFitem-price.mobile .pnew{
        font-size: 1.5em;
    }

    .BFitem-price .more {
        font-size: 14pt;
        transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
    }
    .BFitem-price.mobile .more {
        font-size: 12pt;
    }

    .BFitem-price .more:hover {
        transform: scale(1.1);
    }

    .BFitem-price .more {
        background-color: transparent;
        border: 2px solid #d98c6b;
        color: #d98c6b;
        padding: 7px 10px;
        width: 45%;
    }

    .BFitem-price > div {
        width: 45%;
    }

    /* COMBO BLOCK*/

    .BFcombo-cont {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        border: 5px solid #d79f62;
        padding: 20px 150px 20px 50px;
        margin: -50px 0px 30px 0;
        position: relative;
    }
    .BFcombo-cont.halftablet, .BFcombo-cont.mobile {
        flex-wrap: wrap;
        padding: 35px 10px 50px 10px;;
        justify-content: center;
    }

    .BFcombo-elem {
        width: 30%;
        text-align: center;
        position: relative;
        z-index: 1;
    }
    .BFcombo-elem.halftablet, .BFcombo-elem.mobile{
        width: 45%;
    }

    .BFcombo-elem h3{
        font: bold 18pt/16pt 'Montserrat', arial, sans-serif;
        text-transform: none;
        margin: 0;
    }

    .BFcombo-elem.mobile h3 {
        font-size: 14pt;
    }

    .BFcombo-elem p {
        font-weight: 400;
        font-size: 12pt;
        color: #d98c6b;
        text-decoration: line-through;
    }

    .BFcombo-elem h3.big, .BFcombo-cont > p  {
        font-size: 30pt;
    }
    .BFcombo-elem.mobile h3.big, .BFcombo-cont.mobile > p  {
        font-size: 20pt;
    }

    .BFcombo-elem p.absolute {
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
    }
    .BFcombo-elem.halftablet p.absolute,
    .BFcombo-elem.mobile p.absolute{
        top:30px;
    }
    .BFcombo-present {
        max-width: 226px;
        position: absolute;
        bottom: -120px;
        left: -120px;
    }
    .BFcombo-present.mobile{
        display:none;
    }

    .BFcombo-balls {
        position: absolute;
        bottom: -100px;
        right: -60px;
        z-index: 0;
    }
    .BFcombo-ball {
        max-width: 246px;
    }
    .BFcombo-ball.halftablet,{
        max-width: 150px;
    }
    .BFcombo-ball.mobile{
        top:0;
    }
    .container.items .BFcombo-balls.mobile{
        max-width: 100px;
        bottom: -20px;
        right: -30px;
    }

    .BFcombo-balls.halftablet{
        right: -100px;
    }

    .BFcombo-balls..halftablet p{
        font-size: 40pt;
        right: 60px;
        top: 80px;
    }

    .BFcombo-balls.mobile p{
        font-size: 30pt;
        right: 0px;
        top: 80px;
    }

    .BFcombo-balls p {
        position: absolute;
        font-size: 55pt;
        color: #d79f63;
        font-weight: 400;
        right: 50px;
        top: 60px;
    }

    .BFcombo-balls.mobile p {
        font-size: 40pt;
    }

    .BFcombo-balls p span {
        font-size: 26pt;
    }

    .BFcont-block{
        width: 50%;
        margin-top: 80px;
    }
    .BFcont-block.mobile, .BFcont-block.halftablet {
        width: 100%;
        margin-top: 60px;
    }
    .BFcont-block.full {
        width: 100%;
    }
    .BFbuy-cont.mobile .BFbuy-but {
        font-size: 0.8em;
    }
</style>
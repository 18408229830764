<template>
    <div @click="toggle()" class="dropdown-button">
        <div class="avatar-border">
            <img loading="lazy" ref="parent" class="avatar" :src="avatarUrl" alt="">
        </div>
        <ul ref="menu" :class="computedTogglerClasses">
            <li><router-link to="/mycourses">Мои курсы</router-link></li>
            <li class='i-logout'><a @click=" logout " href="#52d479e4e04c4fd0bfab54bd56281d8a">Выйти</a></li>
        </ul>
    </div>
</template>

<script>
    import {createPopper} from "@popperjs/core";
    import { getUserAvatar } from "../../store/user.js";

    export default {
        name: "AccountDropdown",
        props: {},
        data() {
            return {
                visible: false,
                avatarUrl: getUserAvatar()
                // _popper: null
            }
        },
        watch: {
            visible: {
                immediate: true,
                handler(val) {
                    val ? this.createPopper() : this.removePopper()
                }
            }
        },
        computed: {
            computedTogglerClasses() {
                return this.visible ? ['dropdown', 'show'] : ['dropdown']
            }
        },
        methods: {
            hide() {
                this.visible = false
            },

            toggle() {
                // e.preventDefault()
                this.visible = !this.visible
            },
            removePopper() {
                const clickHandler = 'ontouchstart' in document.documentElement ? 'touchstart' : 'click';
                document.removeEventListener(clickHandler, this.clickHandler)
                if (this._popper) {
                    this._popper.destroy()
                }
                this._popper = null
            },

            logout() {
                this.$auth.logout({
                    returnTo: window.location.origin
                });
            },

            clickHandler(e) {
                var isElement = false
                e.path.forEach(element => {

                    if (element.classList) {
                        isElement ||= element.classList.contains("dropdown-button") || element.classList.contains("dropdown")
                    }
                });
                if (!isElement) {
                    this.hide()
                }
            },
            createPopper() {
                this.removePopper()
                this.$nextTick(() => {

                    this._popper = createPopper(
                        this.$refs.parent,
                        this.$refs.menu,
                        {
                            modifiers: [
                                {
                                    name: 'preventOverflow',
                                    options: {
                                        padding: 10
                                    }
                                },
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, 10]
                                    }
                                }
                            ]
                        }
                    )
                })
                const clickHandler = 'ontouchstart' in document.documentElement ? 'touchstart' : 'click';
                // Define Handler and cache it on the element

                document.addEventListener(clickHandler, this.clickHandler)
            }
        }
    }
</script>

<style scoped>
    .dropdown {
        display: none;
        background-color: white;
        text-align: left;
        padding: 15px 10px 10px 25px;
        z-index: 100;
        box-shadow: 4px 4px 15px rgba(0, 0, 0, .15);
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        font-size: 10pt;
        min-width: 130px;
    }
        .dropdown li {
            list-style: none;
            cursor: pointer;
        }
        .dropdown li a{
            display: inline-block;
            padding: 10px 10px 10px 35px; 
            background: url('~@/assets/membersh/mycourses.svg') no-repeat;
            background-size: 2em;
            background-position: 0.0em 0.3em;
            color: #482431;
        }

            .dropdown li.i-logout a{
                background-position: 0px -8.5em;
            }

    .dropdown.show {
        display: block;
    }

    .avatar {
        width: 42px;
        height: 42px;
        border-radius: 100px;
        position: relative;
        top: 2px;
        left: 0px;
        z-index: 1;
    }

    .avatar-border {
        content:'';
        display: inline-block;
        width: 46px;
        height: 46px;
        border: 2px solid #ffb1a4;
        border-radius: 100px;
        position: relative;
        z-index: 100;
        cursor: pointer;
        text-align: center;
    }
</style>
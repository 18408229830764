<template>
    <svg xmlns="http://www.w3.org/2000/svg"
         :width="width"
         :height="height"
         viewBox="0 0 69.61 72.28"
    >
        <g id="Layer_2" data-name="Layer 2">
            <g id="Main" :style="getStyle">
                <path class="cls-1" d="M.46 61.72c3.7-8.38 5.2-9 8.38-11.94s8-9.62 8.91-11.71 5.67-4.38 10.31-3.59 10.6 2.81 11.47 5.07S41.34 51 41.34 51s-1.59 1.49-2.75-1.53-1.4-8.12-2.67-8.06-2.26.88-6.09 1.07a31.45 31.45 0 01-6.14-.2s-3.11 9.88-8.21 10.14"/>
                <path class="cls-1" d="M10.52 72.11c2.94-8.09 6-9.26 7.52-8.57s12.74-.64 18.88-6.06S55.44 49.39 59 49.72s3.7-2.75-2.21-3.48a31.33 31.33 0 00-11.7 1.1"/>
                <path class="cls-1" d="M27.39 33.88c1.91-2.92 4.17-3.8 8.86-1.72s7.71.89 7.94 6a107.49 107.49 0 01-.45 12.32 4.31 4.31 0 01-2.79-3"/>
                <path class="cls-1" d="M34.17 31.23c3.68-4.15 7.58-1.65 15.47 3.08a65.32 65.32 0 0112.17 9.13s-.92 2.09-3.53.94-11.83-7.1-11.83-7.1M41.69 28.3c7.76-3.46 10.25-4.86 17-3.15l10.08 2.57s-1.44 1.87-5 1.54-5.1.25-8.46-.61S49 31.52 49 31.52"/>
                <path class="cls-1" d="M47.44 25.8a20.46 20.46 0 01-12.65-8.68s4-2 5.67-4.38 8.23 1.13 8.87 3.91"/>
                <path class="cls-1" d="M51.19 21.62c3.52-4.47 3.41-7 2.24-10A16.26 16.26 0 0152.79 6a1.74 1.74 0 00-2.59-1.54 10.71 10.71 0 01-4.94 1.64c-3.07-.11-.37 6.48-.37 6.48"/>
                <path class="cls-1" d="M54.89 15.35c1.32-4.42-2.1-6.55 1.09-9.53s8.16-.15 5.21 2.3-3.29 6.06-3.7 8.38-1.49 7.93-5.66 8.05M55.82 4.81L55 4.08a2.84 2.84 0 00-1.46-.87c-.57-.07-1.23.38-1.15 1"/>
                <path class="cls-1" d="M56.74 16.8l2.89 1.2a3.58 3.58 0 01-1.69 3.22c-.52.31-1.1.47-1.62.75a4.35 4.35 0 00-1.73 1.7M47.69 4.75A4.45 4.45 0 0045 3.82c-1.65.18-2.53 2-3.92 2.93-.79.52-1.75.74-2.56 1.25a2.11 2.11 0 00-1.09 2.39c.51 1.19 2.25 1.06 3.28 1.82M37.41 11.45a11 11 0 00-3.32 5.33l.69.36"/>
                <path class="cls-1" d="M36.61 12.93a2.65 2.65 0 00-2.89.75 7.77 7.77 0 00-1.5 2.79L30.3 21.6c.76 1 2.4.42 3.49-.28s2.79-1.24 3.46-.14M17.14 0v2.33M18.47 4.67h1.34M16.81 8.67V6.33M14.47 5h-1.33"/>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "KIHandFlower",
        props: {
            width: {
                type: [Number, String],
                default: 18
            },
            height: {
                type: [Number, String],
                default: 18
            },
            iconColor: {
                type: String,
                default: '#ffb1a4'
            }
        },
        computed: {
            getStyle () {
                return 'stroke: '+this.iconColor
            }
        }
    }
</script>

<style scoped>
    .cls-1 {
        fill: none;
        stroke-miterlimit: 10
    }
</style>
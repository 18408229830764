<template>
    <div class="main-block" :class="$mq">
        <div class="main-left-img"></div>
        <div class='container main'>
            <div class='main-slide'>
                <div class="main-img-block">
                    <KImage :src="mainImage" imgClass='main-img' alt="Будуарная кукла"/>
                    <div class="main-pink-sq"></div>
                    <div class="main-video-bt" @click="playVideo()">
                        <KImage :src="playVideoImage" imgClass='main-video-i' alt=""/>
                        <p>Посмотрите,<br>как создаются<br>подвижные куклы</p>
                    </div>
                </div>

                <div class='main-slide-text'>
                    <h1>Научись cоздавать невероятную авторскую
                        <br><span class='main-bigger'>куклу</span>
                    </h1>

                    <a class='main-button' href="/book">Хочу сделать куклу</a>

                </div>
            </div>
        </div>
         <div class="main-right-img"></div>
    </div>
</template>
<script>
    import KImage from "../Widgets/KImage";
    import mainImage from "@/assets/main/main-img3.jpg"
    import playVideoImage from "@/assets/main/main-video-play.svg"
    export default {
        components: {
            KImage,
        },
        data() {
            return {
                mainImage,
                playVideoImage
            }
        },
        methods: {
            playVideo() {
                let videoId = 'W-s8FmRHB2w'
                let videoWidth = window.innerWidth < 800 ? window.innerWidth : 800
                let videoHeight = videoWidth / 1.77
                this.$swal.fire({
                    width: videoWidth+'px',
                    background: '#eae2e0',
                    html: "<iframe frameborder=\"0\" allowfullscreen=\"1\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" title=\"YouTube video player\" width=\""+videoWidth+"\" height=\""+videoHeight+"\" src=\"https://www.youtube.com/embed/"+videoId+"?autoplay=1&controls=0&rel=0&modestbranding=0&loop=1&enablejsapi=1&origin=https%3A%2F%2Fkibanovadolls.ru&widgetid=1\"></iframe>",
                    showCloseButton: true,
                    showConfirmButton: false,
                    // closeButtonHtml: ""
                })
            }
        }
    }
</script>
<style>
    .main-block {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
    }

    .main-block.halftablet, .main-block.mobile {
        display: none;
    }

    .main-left-img {
        flex-grow: 1;
        background: url('~@/assets/main/main-left@2x.png') no-repeat;
        background-size: 290px;
        background-position: right;
        /* background-color: grey; */
        position: relative;
        left: 2%;
    }

    .main-right-img {
        flex-grow: 1;
        background: url('~@/assets/main/main-right@2x.png') no-repeat;
        background-size: 315px;
        background-position: left;
        /* background-color: grey; */        
    }

    .container.main {
        flex: 0 1 1000px;
        padding: 0;
        left: 50px;
        z-index: 1;
    }

    .main-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color: pink; */
    }

    .main-slide-text {
        width: 40%;
    }

    .main-slide-text h1 {
        font-size: 3.5em;
        line-height: 0.95;
        margin-bottom: 50px;
    }

    .main-slide-text h1 span.main-bigger {
        font-size: 2.5em;
        line-height: 0.6;
    }

    .main-slide-text::after {
        content: url('../../assets/book/main_curve.svg');
        display: block;
        width: 400px;
        height: 135px;
        position: absolute;
        bottom: -30px;
        right: -150px;
        z-index: 0;
    }

    .main-img-block {
        width: 47%;
        margin-right: 5%;
        position: relative;
    }

    .main-img {
        display: block;
        width: 100%;
        transform: rotate(-3deg);
        position: relative;
        z-index: 1;
    }

    .main-pink-sq {
        display: block;
        width: 50%;
        height: 80%;
        background-color: #FFB1A4;
        position: absolute;
        top: 40px;
        right: 10%;
        transform: rotate(-6deg);
    }

    .main-video-bt {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        background-color: #FFB1A4;
        font-weight: bold;
        font-size: 11pt;
        line-height: 13pt;
        position: absolute;
        bottom: 30px;
        left: -20px;
        z-index: 2;
        background: url('~@/assets/book/main-video-bg.svg') no-repeat;
        width: 280px;
        height: 100px;
        cursor: pointer;
        transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
    }

    .main-video-bt:hover {
        transform: scale(1.05);
    }

    .main-video-i {
        width: 77px;
        margin-right: 20px;
        animation: pulse 1s infinite;
    }

    @keyframes pulse {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.05);
        }
        0% {
            transform: scale(1);
        }
    }

    .main-button {
        display: inline-block;
        position: relative;
        font: 15pt 'Montserrat';
        padding: 25px 100px 25px 40px;
        background-color: #482431;
        color: #FFB1A4;
        cursor: pointer;
        transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
        z-index: 2;
    }

    .main-button:hover {
        transform: scale(1.1);
        color: #FFB1A4;
    }

    .main-button::after {
        content: url('../../assets/book/button_hand.svg');
        display: block;
        width: 68px;
        position: absolute;
        top: 5px;
        right: 20px;
    }

    .main-button::before {
        content: url('../../assets/book/button-bord.svg');
        display: block;
        width: 300px;
        height: 91px;
        /*background-color: yellow;*/
        position: absolute;
        top: -10px;
        left: 20px;
        z-index: 0;
    }

</style>
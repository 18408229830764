import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import VueScrollTo from 'vue-scrollto'
import { setupAuth }  from "./service/auth0"
import { loginUser } from "./service/api"
import VueMq from 'vue3-mq'
import VueYandexMetrika from 'vue-yandex-metrika'
import { getUserFullName, getUserId, getUserEmail, getUserAvatar } from "./store/user";
import Accordion from './components/Widgets/Accordion';
import IframePoster from './plugins/iframe_poster'
import { createI18n } from "vue-i18n";
import LazyLoading from 'vue-lazy-loading'

import VideoBackground from 'vue-responsive-video-background-player'



const i18n = createI18n({
    locale: navigator.language,
    globalInjection: true,
    messages: {},
    fallbackLocale: {
        'hy':   ['ru'],
        'az':   ['ru'],
        'kk':   ['ru'],
        'tg':   ['ru'],
        'uz':   ['ru'],
        'be':   ['ru'],
        'uk':   ['ru'],
        'default': ['en', 'ru']
    },
    silentTranslationWarn: true,
});
let app = createApp(App).use(i18n)
app.use(router)
app.use(store)
app.use(VueSweetalert2)
app.use(VueScrollTo)
app.use(Accordion)
app.use(LazyLoading)

app.component('video-background', VideoBackground);


app.use(VueMq, {
    breakpoints: {
        mobile: 450, halftablet: 700, tablet: 950, laptop: 1100, desktop: Infinity,
    }
})
app.use(VueYandexMetrika, {
    id: 69845800,
    router: router,
    env: process.env.NODE_ENV
    // other options
})

function callbackRedirect(appState) {
    window.UE.pageHit({
        apiKey: 'pseQqn',
        email: getUserEmail(),
        user_id: getUserId(),
        name: getUserFullName(),
        gravatar_url: getUserAvatar()
    });
    // app.$metrika.getClientID()
    loginUser().then(()=> {
        let redirect = store.getters.redirectLogin
        store.commit('setRedirect', '/')
        router.push(redirect)
    })



}
setupAuth({
   scope: 'openid profile email http://kibanovadolls.ru/email',
   domain: process.env.VUE_APP_AUTH_DOMAIN,
   client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
   redirect_uri: process.env.VUE_APP_AUTH_REDIRECT_URI,
   audience: process.env.VUE_APP_AUTH_AUDIENCE
}, callbackRedirect).then((auth) => {
    app.use(IframePoster)
   app.use(auth).mount('#app')
})
// app.mount('#app')
<template>
<div class="header">
  <div class="container nav" :class="$mq">
    <div class="nav" :class="$mq">
      <k-logo class="logo" :class="$mq"/>
      <div class="menu" :class="$mq">
        <router-link to="/dollchiki" class="menu-a">DollЧики</router-link>
<!--        <a href="#courses" class="menu-a">Обучение</a>-->
        <div @click="openMyCourses" v-if="$auth.isAuthenticated.value" href="#" class="mycourses" :class="$mq">
          {{ $t("button.my_courses") }}
          <div class="icon"></div>
        </div>

        <div class='login-block' v-if="!$auth.loading.value">
          <button class='login' :class='$mq' v-if="(!$auth.isAuthenticated.value)" @click="login">
            {{ $t("button.enter") }}
          </button>
          <div v-if="$auth.isAuthenticated.value" class='login-avatar' :class='$mq'>
<!--            <p>Привет, <br>{{ name }}!</p>-->
            <AccountDropdown/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import AccountDropdown from "./Widgets/AccountDropdown";
import KLogo from "./Widgets/KLogo";
import {getUserName} from "@/store/user";
import store from "../store";


export default {
  data() {
    return {
      name: getUserName()
    }
  },
  components: {
    AccountDropdown,
    KLogo
  },
  computed: {
    // getAvatarStyle() {
    //   if (this.$mq === 'mobile') {
    //     if (this.$route.path !== '/mycourses') {
    //       return 'display:none'
    //     }
    //   }
    // },
    // getMyCoursesStyle() {
    //   if (this.$mq === 'mobile') {
    //     if (this.$route.path === '/mycourses') {
    //       return 'display:none'
    //     }
    //   }
    // }
  },
  methods: {
    openMyCourses() {
      this.$router.push('/mycourses')
    },
    login() {
      store.commit('setRedirect', this.$route.fullPath);
      this.$auth.loginWithRedirect();
    }
  },
  i18n: {
    messages: {
      en: {
        button: {
          enter: 'Sign In',
          my_courses: 'My courses'
        }
      },
      ru: {
        button: {
          enter: 'Войти',
          my_courses: 'Мои курсы'
        }
      }
    },
  }
}
</script>

<style scoped>

.container.nav {
  position: relative;
  padding: 0;
  top: 0;
  max-width: 900px;
  z-index: 999;
  font-size: 0.7em;
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
}

.container.nav.mobile {
  width: 100%;
}

.logo {
  position: relative;
  top: -1em;
}

.nav {
  display: flex;
  justify-content: space-between;
  padding: 2em 2em;
  width: 100%;
}

.nav.mobile {
  padding: 1em 0.5em;
}

.menu {
  display: flex;
  align-items: center;
}

  .menu-a {
    color: #482431;
    font-size: 1.1em;
    margin-right: 2em;
    transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);

  }
  .menu a:hover {
    transform: scale(1.1);
  }
  .menu.mobile .menu-a {
    margin-right: 1em;
  }

.menu button {
  /* margin-left: 2em; */
}

button.login, .mycourses {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

button.login {
  padding: 0.4em 1em;
  font-size: 0.9em;
  border-radius: 50px;
  border-width: 0;
}

.mycourses {
  display: block;  
  padding: 0.2em 1em 0.2em 2em;
  color: #482431;
  background-color: #ffb1a499;
  border-radius: 50px;
  border-width: 0;
  margin-right: 1em;
  transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
  cursor: pointer;
  position: relative;
}
  .mycourses .icon {
    width: 2.3em;
    height: 2.2em;
    background: url('~@/assets/membersh/mycourses.svg') no-repeat;
    background-size: 2.3em;
    position: absolute;
    top: -0.3em;
    left: -0.7em;
  }

.mycourses:hover {
  transform: scale(1.1);
}

.mycourses.halftablet,
.mycourses.mobile {
  display: none;
}

/*header.mobile .mycourses {*/
/*  font-size: 0.8em;*/
/*  padding: 0.5em 1em 0.5em 3em;*/
/*  background-size: 1em;*/
/*}*/

/*.login-block {*/
/*  z-index: 100;*/
/*}*/

/*.login {*/
/*  font-weight: 700;*/
/*  background-color: #ffb1a4;*/
/*  padding: 8px 15px;*/
/*  border: none;*/
/*  border-radius: 20em;*/
/*  position: relative;*/
/*  transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);*/
/*  z-index: 1000;*/
/*  font-family: 'Montserrat', sans-serif;*/
/*}*/

/*button.login.mobile {*/
/*  font-size: 8pt;*/
/*}*/

.login:hover {
  transform: scale(1.1);
}

/*.login::before, .login::after {*/
/*  content: url('/img/main/main-login-but.svg');*/
/*  display: block;*/
/*  width: 60px;*/
/*  position: absolute;*/
/*}*/

/*.login::before {*/
/*  right: 5px;*/
/*  top: -4px;*/
/*}*/

/*.login::after {*/
/*  left: -3px;*/
/*  bottom: -5px;*/
/*}*/

.login.tablet, .login.halftablet {
  right: 20px;
}

.login.mobile {
  right: 0;
}

/*.login-avatar {*/
/*  display: flex;*/
/*  align-items: center;*/
/*}*/

/*.login-avatar p {*/
/*  margin-right: 10px;*/
/*  text-align: right;*/
/*  position: relative;*/
/*}*/

/*.login-avatar.mobile p {*/
/*  display: none;*/
/*}*/
</style>
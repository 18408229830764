export const appStore = {

    state() {
        return {
            app: {
                acceptCookie: false,
                loginRedirect: "/"
            }
        }
    },
    getters: {
        acceptCookie: state => {
            return state.app.acceptCookie;
        },
        redirectLogin: state => {
            return state.app.loginRedirect
        }
    },
    mutations: {
        acceptCookie(state) {
            state.app.acceptCookie = true;
        },
        setRedirect(state, uri) {
            state.app.loginRedirect = uri
        }
    },
}
<template>
    <MainNew/>
    <HHeader/>
    <MainItem :items="[4,5,6,7,8,9]"/>
    <MainBook/>
</template>

<script>
    import MainScreen from './MainPage/MainFirstScreen.vue'
    import HHeader from './HomeView/HHeader.vue'
    import MainItem from './MainPage/MainItem.vue'
    import MainNew from "@/components/MainNew";
    import MainBook from "@/components/MainBook";

    export default {
        name: 'LandingView',
        components: {
          MainNew,
            MainScreen,
            HHeader,
            MainItem,
            MainBook,
        }
    }
</script>


<template>
    <div class="container" :class='$mq'>
        <div class="book-cont" :class='$mq'>
            <div class="book-text" :class='$mq'>
                <h2 :class='$mq'>Создай первую авторскую куклу за&nbsp;2&nbsp;месяца</h2>
                <p>Вы пройдете весь путь от чертежа до готовой куклы:</p>
                <ul>
                    <li>С&nbsp;чего начать</li>
                    <li>Как слепить голову куклы</li>
                    <li>Как сделать шарниры</li>
                    <li>Как загрунтовать и&nbsp;расписать</li>
                    <li>Как собрать куклу</li>
                    <li>Как сделать прическу</li>
                    <li>Как легко и&nbsp;быстро сшить костюм</li>
                </ul>

                <KRoundedButton class='button' :class='$mq' text-color="#fff" color="#e03955" @click="openBook">
                    Подробнее о книге
                    <template v-slot:icon>
                        <KIHandFlower icon-color="#FFF" height="50px" width="50px"/>
                    </template>
                </KRoundedButton>
            </div>

            <div class="book-img" :class='$mq'>
                <div class="book-img-cont" :class='$mq'>
                    <KImage :src="require('@/assets/march/pr-book.png')" alt="" width="100%"/>
                </div>
                <div class="book-price" :class='$mq'>
                    <p>2490&#8381;</p>
                    <p class="old" :class='$mq'>3000&#8381;</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import KImage from "@/components/Widgets/KImage";
    import KRoundedButton from "@/components/Widgets/KRoundedButton";
    import KIHandFlower from "@/components/Widgets/Icons/KIHandFlower";

    export default {
    name: "MarchBook",
    components: {
        KImage,
        KRoundedButton,
        KIHandFlower
    }, 
    methods: {
    openBook() {
      this.$router.push('/book')
    }
    }
    }
</script>

<style scoped>
    .container {
        width: 100%;
        max-width: 900px;
        position: relative;
        padding: 0 2em;
        box-sizing: border-box;
    }
    
    .book-cont {
        margin-top: 3em;
    }
        .book-cont.mobile {
            margin-top: 0em;
        }

    h2 {
        font-size: 3em;
        line-height: 1em;
        margin-bottom: 0.5em;
        position: relative;
    }        
        h2.tablet,
        h2.mobile {
            font-size: 2.5em;
        }

    p {
        line-height: 1.2em;
        margin-bottom: 1em;
    }
    
    .book-cont {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
        .book-cont.halftablet,
        .book-cont.mobile {
            flex-wrap: wrap;
            flex-direction: column-reverse
        }
        .book-text {
            width: 48%;
        }
            .book-text.tablet {
                width: 50%;
            }
            .book-text.halftablet,
            .book-text.mobile {
                width: 100%;
            }
        .book-img {
            width: 48%;
        }
            .book-img.halftablet,
            .book-img.mobile {
                width: 100%;
            }
        .book-img-cont {
            transform: rotate(5deg);
        }
        .book-price {
            display: flex;
            font-size: 2.5em;
            justify-content: center;
            position: relative;
            top: -0.35em;
        }            
            .book-price.tablet {
                font-size: 2em;
            }
            .book-price.halftablet,
            .book-price.mobile {
                display: none;
            }
            .book-price p{
                margin-bottom: 0;
            }
            .book-price p.old {
                margin-left: 1em;
                color: #d98c6b99;
                text-decoration: line-through;
            }
    
    ul {
        margin-bottom: 1em;
    }
        ul li {
            margin-bottom: 0.5em;
            margin-left: 1em;
            line-height: 1em;
        }
            li::before {
                content: "—";
                display: inline-block;
                margin-right: 0.5em;
            }

    .button {
        margin-top: 2em;
    }
</style>
<template>
    <footer :class='$mq'>
        <div class="container">
            <!-- <div class="footer-line"></div> -->
            <!-- <KImage :src="require('@/assets/footer-line.svg')" imgClass='footer-line' alt=""/> -->

            <div class="footer-block" :class='$mq'>
                <div class="ft-left" :class='$mq'>
                    <KLogo class="footer-logo"/>
                    <p>ИП Кибанова Л.В.</p>
                    <p>ИНН: 590606721964</p>
                    <p>ОГРНИП: 320595800074248</p>
                    <p>Адрес офиса: г. Сочи, ул. Загородная, 3/8</p>
                    <p>Телефон: +7 912 588 3738</p>
                    <p>Эл. почта: <a class="link" href="mailto:hi@kibanovadolls.ru">hi@kibanovadolls.ru</a></p>
                    <p class='copyr'>Copyright © KibanovaDolls 2020-2021. Все права защищены</p>
                </div>
                <div class="ft-right" :class='$mq'>
                    <a class="ft-soc vk" target='_blank' href="https://vk.com/strasti_sharnir">Vkontakte</a>
                    <a class="ft-soc in" target='_blank' href="https://www.instagram.com/kibanovadolls/">Instagram</a>
                    <a class="ft-soc yt" target='_blank' href="https://youtube.com/channel/UC-0xHId8Riqcdbr8Yj67AXg">Youtube</a>
                    <a class="ft-soc fb" target='_blank' href="https://www.facebook.com/kibanovadoll/">Facebook</a>

                    <p>
                        <router-link  class="link" to="/oferta">Публичная оферта</router-link>
                    </p>
                    <p>
                        <router-link  class="link" to="/politics">Политика конфиденциальности</router-link>
                    </p>
                    <p>
                        <router-link  class="link" to="/payment">Платежи</router-link>
                    </p>

                    <div class="ft-cards mir"></div>
                    <div class="ft-cards vs"></div>
                    <div class="ft-cards mc"></div>

                </div>
            </div>
        </div>
    </footer>
</template>
<script>
    import KImage from "./Widgets/KImage";
    import KLogo from "./Widgets/KLogo";

    export default {
        components: {
            KImage,
            KLogo
        }
    }
</script>
<style>
    /* .footer-line {
        height: 2em;
        background: url('~@/assets/footer-line.svg');
        background-size: 100%;
    } */

    footer {
        margin-top: 3em;
        font-size: 0.8em;
    }

    footer.mobile {
        font-size: 10pt;
    }

    .footer-block {
        display: flex;
        justify-content: space-between;
        padding: 2em 0 2em 0;
    }

    .footer-block.mobile {
        flex-wrap: wrap;
    }

    .ft-right {
        text-align: right;
    }

    .ft-right.mobile {
        width: 100%;
        text-align: left;
    }


    .ft-right p {
        margin-top: 1em;
    }

    .ft-soc, .ft-cards {
        text-align: left;
        display: inline-block;
        margin-left: 1.5em;
        background-image: url('~@/assets/footer-icons.svg');
        background-size: 23em;
        background-repeat: no-repeat;
        text-indent: -50000px;
        cursor: pointer;
        transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
    }
        .ft-right.tablet .ft-soc {
            margin-left: 1.5em;
        }
        .ft-right.halftablet .ft-soc {
            margin-left: 1em;
        }        
        .ft-right.mobile .ft-soc {
            margin-left: 2em;
        }

        .ft-soc {
            width: 38px;
            height: 30px;
            margin-bottom: 20px;
            margin-top: 20px;
            margin-left: 50px;
        }

        .ft-soc:hover {
            transform: scale(1.1);
        }

        .ft-soc.in {
            background-position: -7.6em 0em;
            width: 2em;
        }

        .ft-soc.yt {
            background-position: -14em 0em;
        }

        .ft-soc.fb {
            background-position: -21.8em 0em;
            width: 20px;
        }

    .ft-cards {
        width: 70px;
        height: 40px;
        margin-top: 30px;
    }

    .ft-cards:hover {
        transform: scale(1.1);
    }

    .ft-cards.mir {
        background-position: 0em -12em;
    }

    .ft-cards.vs {
        background-position: -9em -12em;
    }

    .ft-cards.mc {
        background-position: -18em -12em;
    }

    footer .link::after {
        content: "";
        border-top: 1px solid #B23642;
        position: absolute;
        width: 100%;
        left: 0;
        right: 100%;
        bottom: -3px;
        transition: width .4s ease-out;
    }

    footer .link:hover::after {
        width: 0;
    }

    footer .ft-soc::after {
        border: none;
    }

    p.copyr {
        margin-top: 20px;
    }
    .footer-logo {
        display: inline-block;
        margin-bottom: 2em;
    }
</style>
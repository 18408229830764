import axios from 'axios'
import { authPlugin } from './auth0'

const http = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
})

http.interceptors.request.use(function (config) {
        return authPlugin.getTokenSilently().then(token => {
            config.headers["Authorization"] = "Bearer " + token;
            return config
        })
}, function(error) {
    return Promise.reject(error);
})

function loginUser() {
    return http.get('/user/login')
}

function loadVideo(videoId) {
    return http.get('/new_video/info/'+videoId)
}

function loadQiwiUrl(goodId) {
    return http.get('/service/qiwi?good_id='+goodId)
}

function loadArsenalPaySign(goodId) {
    return http.get('/service/arsenalpay/sign?good_id='+goodId)
}

function loadCourses() {
    return http.get('/user/courses')
}

function loadChapters() {
    return http.get('/user/chapters')
}

function createOrder(courseId, paymentType) {
    return http.post('/user/orders', '{"course_id": '+courseId+',"payment_type":"'+paymentType+'"}')
}

function getOrder(orderId) {
    return http.get('/user/orders/'+orderId,)
}

function getArsenalPaySign(orderId) {
    return http.get('/service/arsenalpay/sign?order_id='+orderId)
}

function log(level, message) {
    return http.post('/user/log', '{"level": "'+level+'","message":"'+message.replace(/(?:\r\n|\r|\n)/g, '<br>')+'"}')
}

function getEmailFaceCheck() {
    return http.get('/user/send_face_check')
}

function getEmailFaceCheck3() {
    return http.get('/user/send_face_check/3')
}

export {
    loginUser,
    loadVideo,
    loadChapters,
    loadCourses,
    loadQiwiUrl,
    loadArsenalPaySign,
    createOrder,
    getOrder,
    getArsenalPaySign,
    log,
    getEmailFaceCheck,
    getEmailFaceCheck3
}
<template>
    <div class="container" :class="$mq">
        <h2 id='price'>Видеокурсы<br> <span>дополнительно</span></h2>
    </div>
</template>

<style scoped>

    h2 {
        font: 3.5em/1em 'Kudryashev', sans-serif;
        text-align: center;
        margin-top: 2em;
    }
    .container.mobile h2 {
      font-size: 2.5em;
    }

    h2 span {
        font: 50pt/50pt 'TheArtist', sans-serif;
        color: #FF7F80;
        position:relative;
        top: -20px;
        right: -1.5em;
    }
    .container.mobile h2 span{
        font-size: 1em;
    }
</style>
<template>
  <div :class="[getClasses, 'main-container', $mq]">
    <Head/>
    <router-view/>
    <Footer/>
    <div v-if="showCookieBanner" class="cookie" :class='$mq'>
      <div class="cookie-flex">
        <p>Мы используем файлы
          <router-link to="/politics">куки</router-link>
          , чтобы улучшить сайт для вас.
        </p>
        <button class="cookie-b" @click="saveCookie">Хорошо</button>
      </div>
    </div>
  </div>
  <KEnd />
  <!--  <NYPush v-if="isBannerVisible"/>-->
</template>

<script>
import Head from './components/Head.vue'
import Footer from './components/Footer.vue'
import KEnd from "./components/Widgets/KEnd";


export default {
  name: 'App',
  components: {
    Head,
    Footer,
    KEnd
  },
  watch: {
    '$route'(to) {
      document.title = to.meta.title || 'Kibanova Dolls - мастерская авторской куклы'
      if (this.$route.query.error_description == "missing-email") {
        this.$router.push("/error_mail")
      }
    }
  },
  mounted() {
    if (this.$route.query.error_description == "missing-email") {
      this.$router.push("/error_mail")
    }
  },
  computed: {
    isBannerVisible() {
      return (this.$route.path != '/newyear' && this.$route.path != '/facemistakes')
    },
    getClasses() {
      if (this.$route.path == '/mycourses' || this.$route.path == '/mycourses/payments') {
        return 'shadow';
      }
      if (this.$route.path == '/newyear') {
        return 'black';
      }
      if (this.$route.path == '/dollchiki') {
        return ['noheader','brown'];
      }
      if (this.$route.path == '/beginners') {
        return 'violet';
      }
      if (this.$route.path == '/facemistakes') {
        return 'green';
      }
      if (this.$route.path == '/facecheck') {
        return 'violet';
      }
      if (this.$route.path == '/facecheck2') {
        return 'blue';
      }
      if (this.$route.path == '/facecheck3') {
        return 'violet';
      }
      if (this.$route.path == '/facecheck4') {
        return 'blue';
      }
      if (this.$route.path == '/book') {
        return 'blue';
      }
      if (this.$route.path == '/bjd') {
        return 'lightblue';
      }
      if (this.$route.path == '/bjd2') {
        return 'lightblue';
      }
      if (this.$route.path == '/') {
        return 'new';
      }
      if (this.$route.path == '/insta6') {
        return  ['new','nomenu'];
      }
      if (this.$route.path == '/insta') {
        return 'taplink';
      }
      if (this.$route.path == '/inst') {
        return 'taplink';
      }
      return ''
    },
    showCookieBanner() {
      return !this.$store.getters.acceptCookie
    }
  },
  methods: {
    saveCookie() {
      this.$store.commit('acceptCookie');
    }
  }
}
</script>

<style>
.nomenu .menu-a {
  display: none;
}

.lightblue .menu-a{
  color: #274d5d;
}

.new .menu-a,
.violet .menu-a,
.blue .menu-a {
  color: white;
}

.brown {
  background-color: #36251d;
}
  .brown a {
    color: rgb(217, 140, 107);
  }
  .brown a:hover {
    color: white;
  }

.noheader .header{
  display: none;
}

.brown footer .ft-soc,
.new footer .ft-soc,
.violet footer .ft-soc,
.blue footer .ft-soc {
  background-position-y: -6.4em;
}
.brown footer .ft-cards,
.new footer .ft-cards,
.violet footer .ft-cards,
.blue footer .ft-cards {
  background-position-y: -18em;
}
.brown footer a,
.new footer a,
.violet footer a,
.blue footer a {
  color: rgb(217, 140, 107);
}
  .brown footer a::after,
  .new footer a::after,
  .violet footer a::after,
  .blue footer a::after {
    border-color: rgb(217, 140, 107);
  }
  .brown footer a:hover,
  .new footer a:hover,
  .violet footer a:hover,
  .blue footer a:hover {
    color: white;
  }

.shadow .header {
  box-shadow: 0em 0em 1em #cccccc99;
}
.shadow .header .container.nav {
  max-width: 1100px;
}
.new {
  background-color: #25072e;
}
  .new .logo .cls-1, .new .logo p {
    fill: #ffffff;
    color: #ffffff;
  }
  .new .logo p {
    border-left: 1px solid #ffffff44;
  }
  .new .menu .mycourses {
    margin-left: 2em;
    color: white;
    background-color: #ffb1a433;
  }
  .new .menu button.login {
    color: white;
    background-color: #ffb1a455;
  }
  
  .brown .mycourses .icon,
  .new .mycourses .icon,
  .blue .mycourses .icon,
  .violet .mycourses .icon {
    background-position-y: -5.5em;
  }

.new.main-container {
  padding-top: 0;
}

.new .mycourses, .new .login {
  color: #000000;
  background-color: rgba(71, 71, 71, 0.1);
}

.taplink.mobile footer, .taplink.mobile header a.mycourses, .taplink.mobile .login-block,
.taplink.tablet footer, .taplink.tablet header a.mycourses, .taplink.tablet .login-block,
.taplink.halftablet footer, .taplink.halftablet header a.mycourses, .taplink.halftablet .login-block {
  display: none;
}

.taplink.mobile header,
.taplink.tablet header,
.taplink.halftablet header {
  text-align: center;
  justify-content: center;
}

.lightblue {
  background-color: #d3e9e3;
  color: #274d5d;
}

.lightblue .logo .cls-1, .lightblue .logo p {
  fill: #274d5d;
  color: #274d5d;
}

.lightblue .mycourses, .lightblue .login {
  color: #274d5d;
  background-color: #274d5d19;
  border-radius: 50px;
}

.black, .green, .violet, .blue, .brown, .new {
  color: white;
}

.blue {
  background-color: #023a4d;
}

.blue .login, .blue .mycourses {
  background-color: #00000099;
  color: #f2dde3;
  border-radius: 20em;
}

.blue header {
  max-width: 920px;
}

.blue.mobile header {
  padding-top: 1.5em;
}

.violet {
  background-color: #1d0c29;
}

.violet .logo .cls-1, .violet .logo p {
  fill: #e8dac4;
  color: #e8dac4;
}

.violet .mycourses, .violet .login {
  color: #e8dac4;
  background-color: #90453e;
  border-radius: 50px;
  font-size: 0.9em;
  box-shadow: 0em 0em 1em #90453e;
  font-weight: 600;
}

.violet .mycourses {
  background-position: 1em -10em;
}

.violet.mobile .mycourses {
  background-size: 1.5em;
}

.black {
  background-color: #2e0530;
}

.black .logo .cls-1, .black .logo p {
  fill: #ce955e;
  color: #ce955e;
}

.black.halftablet .logo .cls-1, .violet.halftablet .logo .cls-1, .blue.halftablet .logo .cls-1,
.black.mobile .logo .cls-1, .violet.mobile .logo .cls-1, .blue.mobile .logo .cls-1,
.black.halftablet .logo p, .violet.halftablet .logo p, .blue.halftablet .logo p,
.black.mobile .logo p, .violet.mobile .logo p, .blue.mobile .logo p {
  fill: white;
  color: white;
}

.black .mycourses {
  color: white;
  background-color: #2e0530df;
}

.black.mobile .mycourses {
  background-color: rgba(194, 98, 81, 0.5);
  background-position-y: -119px;
}

.black .login-avatar {
  background-color: #2e0530df;
  padding: 0 20px;
  box-shadow: 0 0 20px 11px #2e0530ff;
  border-radius: 50px;
}

.black.halftablet .login-avatar,
.black.mobile .login-avatar {
  box-shadow: none;
  background-color: transparent;
  padding: 0;
}

.black .splide__arrow {
  z-index: 2;
}

.black .login {
  border-radius: 20px;
  box-shadow: 5px 5px 10px 0px #2e0530df;
  background-color: rgb(194, 98, 81);
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 9pt;
}

.black.mobile .login {
  background-color: #2e0530;
  box-shadow: none;
  color: #ce955e;
  font-weight: 700;
}

.green {
  background-color: #001B32;
}

.green .logo .cls-1, .green .logo p,
.blue .logo .cls-1, .blue .logo p {
  fill: white;
  color: white;
}

.green .mycourses {
  color: white;
  background-color: #001B32;
}

.green header {
  margin-bottom: 0px;
}

.green footer a, .blue footer a {
  color: #d98c6b;
}

.green footer a::after, .blue footer a::after {
  border-top: 1px solid #d98c6b;
}

.green footer a.logo::after {
  border: none;
}

.green footer .ft-soc::after {
  border: none;
}


@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TheArtist';
  src: url('/fonts/made_theartist_script.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kudryashev';
  src: url('/fonts/Kudryashev%20Display_Contrast%20Sans.woff') format('woff'),
  url('/fonts/Kudryashev%20Display_Contrast%20Sans.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  overflow-x: hidden;
}

body {
  color: #482431;
  overflow-x: hidden;
}


.container.nav.mobile {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

body div#app {
  font-family: 'Montserrat', Arial, sans-serif;
  font-size: 13pt;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.7;
  color: #482431;
}

.link {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  cursor: pointer;
}

.link::after {
  content: "";
  border-top: 1px solid #B23642;
  position: absolute;
  width: 100%;
  left: 0;
  right: 100%;
  bottom: -3px;
  transition: width .4s ease-out;
}

.link:active, .link:hover {
  outline: 0;
}

body a, button.link {
  color: #B23642;
  text-decoration: none;
  position: relative;
  transition: color .3s ease-in-out;
}

body a:hover {
  color: #482431;
  cursor: pointer;
}

/*a::after {
    content: "";
    border-top: 1px solid #B23642;
    position: absolute;
        right: 100%;
        left: 0;
        bottom: -3px;
     transition: right .4s ease-out;
}

    a:hover::after {
        right:0;
    }

ul.nav li a:hover{
    color:#B23642;
}*/


.container {
  max-width: 1140px;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
}

h1, h2, h3 {
  font-family: 'Kudryashev', serif;
}

.popVK {
  background-color: #e9e2e1;
  font: 12pt/15pt 'Montserrat', sans-serif;
  color: #482431;
  padding: 30px;
  text-align: left;
  position: relative;
}

.popVK::after {
  content: '';
  display: block;
  background: url('./assets/book/pop-doll.jpg') no-repeat;
  background-size: 270px;
  width: 300px;
  height: 350px;
  position: absolute;
  bottom: 0;
  right: -79px;
  z-index: 0;
}

.popVK-cont {
  z-index: 10;
  position: relative;
}

.popVK h3 {
  font: bold 27pt/27pt 'Montserrat', sans-serif;
  text-transform: initial;
  margin-bottom: 40px;
}

.popVK p {
  width: 60%;
  margin-bottom: 20px;
}

.popVK p b {
  font-weight: 600;
}

.popVK a {
  color: #482431;
  cursor: pointer;
  font-size: 10pt;
}

.popVK a::after {
  content: "";
  border-top: 1px solid #482431;
  position: absolute;
  width: 100%;
  left: 0;
  right: 100%;
  bottom: -3px;
  transition: width .4s ease-out;
}

.popVK a:hover::after {
  width: 0;
}

.swal2-close.close-bt {
  display: block;
  width: 23px;
  height: 23px;
  background: url('./assets/book/pop-up.svg') no-repeat;
  background-size: 80px;
  background-position-x: -66px;
  color: #482431;
  width: 10pt;
  font: 14pt/14pt 'Montserrat', sans-serif;
  margin: 12px 15px 0 0;
  transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
}

.swal2-close.close-bt:hover {
  background: url('./assets/book/pop-up.svg') no-repeat;
  background-size: 80px;
  background-position-x: -66px;
  transform: scale(1.5);
}

.pop-bt {
  background-color: #482431;
  border: none;
  color: #ffb1a4;
  font: bold 14pt/14pt 'Montserrat', sans-serif;
  padding: 20px 40px 20px 85px;
  position: relative;
  margin-bottom: 40px;
  transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
}

.pop-bt:hover {
  transform: scale(1.1);
}

.pop-bt::before {
  content: '';
  display: block;
  width: 40px;
  height: 18px;
  background: url('./assets/book/pop-up.svg') no-repeat;
  background-size: 67px;
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
}

.pop-bt::after {
  content: url('./assets/book/button-bord.svg');
  display: block;
  width: 230px;
  height: 91px;
  /*background-color: yellow;*/
  position: absolute;
  top: -5px;
  left: 0px;
  z-index: 0;
}

div.swal2-popup, div.swal2-content {
  padding: 0;
}

.cookie {
  position: fixed;
  background-color: #ffdad3;
  font-size: 11pt;
  line-height: 1.4;
  padding: 20px;
  max-width: 370px;
  bottom: 2%;
  left: 2%;
  z-index: 9998;
  color: #482431;
  /* width: 100%;
  box-sizing: border-box; */
}

.cookie.tablet, .cookie.halftablet, .cookie.mobile {
  width: 70%;
}

.cookie-flex {
  max-width: 900px;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
}

.cookie.mobile .cookie-flex {
  flex-direction: column;
  font-size: 10pt;
  line-height: 1.1;
}

.cookie.mobile .cookie-flex p {
  width: 100%;
  margin-bottom: 10px;
}

.cookie.mobile .cookie-flex button {
  width: 50%;
  padding: 10px 20px;
}

.cookie-flex p {
  width: 80%;
}

.cookie-flex button {
  width: 15%;
  min-width: 80px;
  background-color: #482431;
  border: none;
  color: #FFB1A4;
  font-weight: 600;
  transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
}

.cookie-flex button:hover {
  transform: scale(1.1);
}
</style>
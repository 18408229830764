<template>
    <div class="end">
        <div class="end-text">
            <p>Этот сайт больше не функционирует. 
            Если вы хотите получить доступ к своим курсам, пожалуйста, 
            <a href="https://vk.com/kibanovadolls">напишите мне Вконтакте</a>, 
            в сообщении укажите ваш email, с помощью которого вы зарегистрированы на этом сайте. </p>

            <a class="button" href="https://vk.com/kibanovadolls">Написать</a>
        </div>
    </div>
</template>

<script>
export default {
  name: "KEnd",
}
</script>

<style scoped>
    .end {
        width:100%; 
        padding: 1em 1em 2em 1em;
        background-color: rgb(215, 102, 102);
        color: rgba(255, 255, 255, 0.8);
        position: fixed;
        top:0;
        right:0;
        left:0;
        z-index: 99999;
        font-size: 0.9em;
        line-height: 1.2em;
        }

    a {
        color: white;
        text-decoration: underline;
    }

    .button {
        display: block;
        text-decoration: none;
        text-align: center;
        color: rgb(255, 255, 255);
        background-color: rgb(170, 13, 40);
        box-shadow: rgb(97, 9, 24) 0px 5px 25px 0px;
        border-radius: 10em;
        padding: 0.75em 2em;
        position: absolute;
        width: 50%;
        bottom: -3.5em;
        margin-left: 50%;
        transform: translateX(-50%);
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);

    }

        .button:hover {
            background-color: rgb(116, 8, 26);
        }

    .end-text {
        width: 80%;
        max-width: 800px;
        margin: 0 auto;
        position: relative;
        }
</style>
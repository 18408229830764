<template>
  <div class="main-page">
    <!-- VIDEO -->
    <video-background
      :poster="require('@/assets/main/new/cover.jpg')"
      src="/files/Video1080p.mp4"
      :sources="[
        {src: '/files/Video720p.mp4', res: 900, autoplay: true},
        {src: '/files/Video360p.mp4', res: 638, autoplay: true}
      ]"
      class="video-bg"  :class="$mq"
    >
      <div class="video-container">
      <div class="video-fill"></div>
      
      <div class="container" :class="$mq">
        <h1>Создай невероятную подвижную <span class="h1-doll">куклу </span><span class="h1-script">за 2 месяца</span>
        </h1>
      </div>

      <div class="video-bottom"></div>

      <KIDown class="icon-down"/>
      </div>
    </video-background>

    <!-- COURSE -->
    <div class="main-courses" id="courses">
      <div class="container" :class="$mq">
        <h2>Что вы хотите создать?</h2>
        <div class="course-light-pink" :class="$mq"></div>
        <div class="course-light-blue" :class="$mq"></div>
        <div class="main-courses-flex" :class="$mq">
          <div class="main-courses-block" :class="$mq" @click="openBeginners">
            <div class="text-course">
              <p>Для начинающих</p>
              <h3>Будуарную<br> куклу</h3>
            </div>
            <div class="bodoir-course" :class="$mq">
              <KImage :src="require('@/assets/main/new/bodoit.png')" width="100%"/>
            </div>
            <KRoundedButton class='march-main-button' :class='$mq' text-color="#fff" color="#d76666">
              Подробнее о курсе
              <template v-slot:icon>
                <KIHandFlower icon-color="#FFF" width="50px" height="50px"/>
              </template>
            </KRoundedButton>
          </div>

          <div class="main-courses-block" :class="$mq" @click="openBJD">
            <div class="text-course">
              <p>Для продвинутых</p>
              <h3>Шарнирную<br> куклу</h3>
            </div>
            <div class="bodoir-course bjd" :class="$mq">
              <KImage :src="require('@/assets/main/new/bjd.png')" width="100%"/>
            </div>
            <KRoundedButton class='march-main-button' :class='$mq' text-color="#fff" color="#4f99a3">
              Подробнее о курсе
              <template v-slot:icon>
                <KIHandFlower icon-color="#FFF" width="50px" height="50px"/>
              </template>
            </KRoundedButton>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KImage from "@/components/Widgets/KImage";
import KRoundedButton from "@/components/Widgets/KRoundedButton";
import KIHandFlower from "@/components/Widgets/Icons/KIHandFlower";
import KIDown from "@/components/Widgets/Icons/KIDown";

export default {
  name: "MainNew",
  components: {
    KImage,
    KRoundedButton,
    KIHandFlower,
    KIDown
  },
  methods: {
    openBJD() {
      this.$router.push('/bjd')
    },
    openBeginners() {
      this.$router.push('/beginners')
    }
  }
}
</script>

<style scoped>
.main-page {
  /*background-color: #25072e;*/
  /*color: white;*/
  font-size: 0.9em;
  margin-top: -7.5em;
}

.main-page a {
  color: white;
}

.container {
  width: 900px;
  position: relative;
  padding: 0 2em;
  box-sizing: border-box;
}
.container.tablet,
.container.halftablet,
.container.mobile{
  width: 100%;
}

/* MAIN SCREEN */
.video-container {
  height: 100vh;
  /*background-image: url('~@/assets/main/new/cover.jpg');*/
  /*background-size: cover;*/
  display: flex;
  align-items: center;
  position: relative;
}

.video-bg {
  height: 100vh;
}
  .video-bg.mobile {
    height: 100vh;
  }

.video-fill {
  background-color: #30091c55;
  position: absolute;
  width: 100%;
  height: 100vh;
}

.video-bottom {
  background: linear-gradient(#25072e00, #25072e);
  position: absolute;
  width: 100%;
  height: 10em;
  bottom: 0;
}

.icon-down {
  position: absolute;
  bottom: 2em;
  left: 50%;
  transform: translateX(-50%);
}


.container.nav {
  position: absolute;
  padding: 0;
  top: 0;
  max-width: 900px;
  left: 50%;
  transform: translateX(-50%);
}

h1 {
  font-size: 3.5em;
  line-height: 1em;
  max-width: 7em;
  position: relative;
}
  .container.halftablet h1 {
    font-size: 2.5em;
  }
.container.mobile h1 {
    font-size: 2.5em;
  }

span.h1-doll {
  font-size: 2.4em;
  line-height: 0.6em;
}

span.h1-script {
  font-family: "TheArtist", sans-serif;
  font-size: 1.2em;
  position: absolute;
  right: 0.5em;
  top: 100%;
}

/* MAIN COURSES */
h2 {
  font-size: 3.5em;
  line-height: 1em;
  text-align: center;
}
.container.mobile h2 {
  font-size: 2.5em;
}

h3 {
  font-size: 2em;
  font-family: "Montserrat";
  line-height: 1.2em;;
}

.main-courses-flex {
  display: flex;
  justify-content: space-around;
}

.main-courses-flex.halftablet,
.main-courses-flex.mobile {
  flex-direction: column;
  align-items: center;
}

.main-courses-block {
  width: 40%;
  position: relative;
  cursor: pointer;
}
  .main-courses-block:hover .march-main-button {
    transform: scale(1.1);
  }
  .main-courses-block.halftablet {
    width: 80%;
    margin-bottom: 2em;
  }
  .main-courses-block.mobile {
    width: 90%;
    margin-bottom: 2em;
  }

.main-courses-block p {
  color: #ffffff99;
}

.bodoir-course {
  width: 320px;
  margin-bottom: -3em;
  position: relative;
  right: -5em;
  z-index: 0;
}
.bodoir-course.mobile.bjd {
  right: -2em;
}

.text-course {
  position: absolute;
  bottom: 10em;
  z-index: 1;
}

.course-light-pink, .course-light-blue {
  position: absolute;
  z-index: 0;
  width: 800px;
  height: 800px;
  bottom: -200px;
}
.course-light-pink.halftablet, .course-light-blue.halftablet,
.course-light-pink.mobile, .course-light-blue.mobile{
display: none;
}


.course-light-pink {
  background: radial-gradient(circle 400px, #d76666, transparent 100%);
  left: -100%;

}

.course-light-blue {
  background: radial-gradient(circle 400px, #4f99a3, transparent 100%);
  right: -100%;
}
.march-main-button.mobile {
  display: inline-flex;
}
</style>

<style>
  .icon-down {
    animation: move-down 1s ease infinite;
  }

  @keyframes move-down {
    0%, 100% {
      bottom: 2em
    }
    50% {
      bottom: 1em;
    }
  }
</style>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" 
         :width="width"
         :height="height"
         viewBox="0 0 224.42 97.89"
    >
        <g id="Layer_2" data-name="Layer 2" :style="getStyle">
            <g id="Layer_1-2" data-name="Layer 1">
                <path class="cls-1" d="M55.12 68.76c4.57 4.94 7 13.83 17.27 21.39a33.26 33.26 0 0020 6.24s15.63.47 5.66 0-27.3-34.21-40.32-40.86C53.36 53.3 45.71 54.81 41 57c5.07 1.89 11.65 9.11 14.12 11.76zM49.13 70.47a32.59 32.59 0 01-19.62 15.78 25.46 25.46 0 01-13 .22 21.76 21.76 0 01-6.1-2.6 21.19 21.19 0 01-5-4.39A23.33 23.33 0 01.17 67.42a22.66 22.66 0 012.29-12.89A23.88 23.88 0 0111.24 45a19.66 19.66 0 0112.39-2.9 20.27 20.27 0 00-11.8 3.77A23.5 23.5 0 004 55.26a21.71 21.71 0 00-1.9 11.92A21 21 0 003.74 73a22.21 22.21 0 003.15 5.2 19.83 19.83 0 0010.05 6.51 24.74 24.74 0 0012.21.1c8.12-1.81 15.27-7.28 19.98-14.34zM106.41 97.89a1.5 1.5 0 010-3c17.37 0 30.47-13 30.47-30.27 0-27-17.13-34.24-27.25-34.24H96.27v21.13a1.5 1.5 0 11-3 0v-22a1.4 1.4 0 01-.12-.58 1.5 1.5 0 011.5-1.5h15c10.46 0 30.25 7.78 30.25 37.24-.02 18.92-14.41 33.22-33.49 33.22zM160.8 97.28c-14.91 0-18.8-12.55-18.8-19.19 0-6.12 4.75-19.53 18.85-19.53 15.39 0 18.85 14.46 18.85 19.53s-3.51 19.19-18.9 19.19zm0-35.72c-11.86 0-15.8 11.35-15.8 16.53 0 5.6 3.31 16.19 15.85 16.19 12.94 0 15.85-12 15.85-16.19s-2.96-16.53-15.9-16.53zM184.27 96.6a1.5 1.5 0 01-1.5-1.5V46.79a1.5 1.5 0 113 0V95.1a1.5 1.5 0 01-1.5 1.5zM191.67 96.6a1.5 1.5 0 01-1.5-1.5V46.79a1.5 1.5 0 113 0V95.1a1.5 1.5 0 01-1.5 1.5zM210.84 97.62c-7.8 0-14.65-5.55-14.94-5.79a1.5 1.5 0 011.91-2.31c.06 0 6.32 5.1 13 5.1 6.22 0 10.58-3.5 10.58-8.5 0-4-2.45-4.7-11.81-6.58l-.75-.15c-11-2.23-11.6-8.56-11.6-9.81v-.19c-.1-7.53 5.38-9.74 10-10.27 8.52-1 13.66.93 16.18 6a1.5 1.5 0 11-2.68 1.34c-1.37-2.73-4-5.38-13.16-4.34-7.43.85-7.36 5.5-7.34 7.25v.23c0 .84.45 5.11 9.2 6.87l.74.15c9.17 1.84 14.22 2.85 14.22 9.52s-5.69 11.48-13.55 11.48z"/>
                <path class="cls-1" d="M27.41 7.34c0-4.39-4.8-6.92-4.8-6.92s5.26-.84 8.71-.15c6.36 1.27 6.36 8.37 6.36 10.17l.38 81a6.25 6.25 0 002.55 5.24H24.77s2.64-.75 2.64-5.24zM63.08 46.93c3.85-4.48 7.09-10.57 7.09-12.18s1-4.15-5.09-6.09h20.11c-6.06 1.94-8.93 4-13.41 9.07"/>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "KILogoNew",
        props: {
            width: {
                type: [Number, String],
                default: 104
            },
            height: {
                type: [Number, String],
                default: 46
            },
            iconColor: {
                type: String,
                default: '#482431'
            }
        },
        computed: {
            getStyle () {
                return 'fill: '+this.iconColor
            }
        }
    }
</script>

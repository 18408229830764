<template>
    <div :style="getStyle">
        <slot/>
        <span :class="[getIconClass, $mq]">
            <slot name="icon"></slot>
        </span>
    </div>
</template>

<script>

    export default {
        name: "KRoundedButton",
        props: {
            textColor: {
                type: String,
                default: '#ffb1a4'
            },
            color: {
                type: String,
                default: '#482431'
            },
            padding: {
                type: String,
                default: null
            }
        },
        computed: {
            getStyle() {
                return 'color:'+this.textColor+
                    (this.padding ? ';padding:'+this.padding:'')+
                    ';background-color:'+this.color+
                    ';box-shadow: 0 0 25px 0px '+this.color;
            },
            getIconClass() {
                return this.$slots.icon ? "icon" : ""
            }
        }
    }
</script>

<style scoped>
    div {
        display: flex;
        justify-content: center;
        align-items: center;
        font: 15pt 'Montserrat';
        padding: 0.5em 2em;
        cursor: pointer;
        transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
        border-radius: 37px;
        position: relative;
        text-align: center;
    }

    div:hover {
        transform: scale(1.1);
    }
    div {
        /*display: flex;*/
        flex-direction: row;
    }
    div * {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
    }

    .icon {
        margin-left: 1em;
    }
    .icon.tablet, .icon.phone, .icon.halftablet {
        margin-left: 5px;
    }
</style>
import { authPlugin } from "../service/auth0";
import md5 from 'md5'

function getUserId() {
    return md5(authPlugin.user.value.email)
}

function getUserName() {
    return getUserFullName().split(' ')[0]
}

function getUserFullName() {
    if (authPlugin.isAuthenticated && authPlugin.user.value) {
        if (authPlugin.user.value.name) {
            return authPlugin.user.value.name
        }
        if (authPlugin.user.value.given_name) {
            return authPlugin.user.value.given_name+" "+authPlugin.user.value.family_name
        }
    } else {
        return ''
    }
    return ''
}

function getUserAvatar() {
    if (authPlugin.isAuthenticated) {
        return authPlugin.user.value.picture
    } else {
        return ''
    }
}

function getUserEmail() {
    if (authPlugin.isAuthenticated) {
        return authPlugin.user.value.email
    } else {
        return ''
    }
}

export { getUserId, getUserAvatar, getUserName, getUserEmail,getUserFullName }
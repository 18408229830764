<template>
    <div class="BFbuy-cont" :class='[$mq, extraClass]'>
        <KRoundedButton text-color="#FFF" color="#d98c6b" class="BFbuy-but" @click="tryToBuy(goodId)" >{{$t('button.buy')}}</KRoundedButton>
    </div>
</template>

<script>
    import KImage from "./KImage";
    import KRoundedButton from "./KRoundedButton";
    export default {
        name: "KBuyButton",
        props: {
            goodId: Number,
            extraClass: String
        },
        components: {
            KRoundedButton,
            KImage
        },
        methods: {
            tryToBuy(id) {
                this.$router.push("/item/"+id)
            },
        },
        i18n: {
            messages: {
                en: { button: { buy: 'Buy it now' } },
                ru: { button: { buy: 'Купить' } }
            },
        }
    }
</script>

<style>
    .BFbuy-cont {
        font-size: 14pt;
        transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
    }
    .BFbuy-cont:hover {
        transform: scale(1.1);
    }
    .BFbuy-cont.combo {
        right: 0;
        left: 0;
        margin: 0 auto;
        position: absolute;
        top: 2em;
    }

    .BFbuy-cont.combo.halftablet,
    .BFbuy-cont.combo.mobile {
        right: 0;
        left:0;
        margin: 0 auto;
        bottom: -110px;
        width: 120px;
    }
    .BFbuy-but {
        background-color: #d98c6b;
        border: none;
        color: white;
        font-weight: 600;
        position: relative;
        /*padding: 8px 20px;*/
        width: auto;
    }
</style>
import { createStore } from 'vuex'
import { appStore } from "./app";
import createPersistedState from "vuex-persistedstate";

const appState = createPersistedState({
    paths: ['appStore']
})

const store = createStore({
    state () {
        return {
            goods: [
                {
                    id:1,
                    name: 'Золушка',
                    desc: 'Книга по созданию будуарной куклы',
                    benefits: [
                        "Электронная книга",
                        "",
                        "1 чертеж куклы <br>(детский)",
                        "Доступ в закрытую группу Вконтакте",
                        "Вечный доступ к материалам",
                        "",
                        ""
                    ],
                    count: 0,
                    price: 2000,
                    priceorig: 2000,
                },
                {
                    id: 2,
                    name: 'Барыня',
                    desc: 'Курс по созданию будуарной куклы',
                    price: 5000,
                    priceorig: 7500,
                    benefits: [
                        "Электронная книга",
                        "Видеоматериалы",
                        "2 чертежа куклы <br>(взрослой и детской)",
                        "Доступ в закрытую группу Вконтакте",
                        "Вечный доступ к материалам",
                        "Чат с обратной связью",
                        ""
                    ],
                    count: 25,
                },
                {
                    id: 3,
                    name: 'Княгиня',
                    desc: 'Курс по созданию будуарной куклы',
                    price: 45000,
                    priceorig: 45000,
                    benefits: [
                        "Электронная книга",
                        "Видеоматериалы",
                        "2 чертежа куклы <br>(взрослой и детской)",
                        "Доступ в закрытую группу Вконтакте",
                        "Вечный доступ к материалам",
                        "Чат с обратной связью",
                        "3 личных онлайн-встречи (по 45мин)"
                    ],
                    count: 2,
                },
                {
                    id:4,
                    name: 'Анатомия <br/><span>головы</span>',
                    desc: 'Хотите узнать как слепить красивое анатомичное лицо куклы? Получите 4-ех часовой МК по анатомии лица.',
                    benefits: [
                        'tJBJCXzpzxk'
                    ],
                    count: 0,
                    price: 2000,
                    priceorig: 2000,
                },
                {
                    id:5,
                    name: 'Анатомия <br/><span>тела</span>',
                    desc: 'Хотите познать человеческое тело? Как его слепить анатомично и реалистично? Смотрите 4-ех часовой МК по анатомии тела',
                    benefits: [
                        'vsx8EAUYfrc'
                    ],
                    count: 0,
                    price: 3000,
                    priceorig: 3000,
                },
                {
                    id:6,
                    name: '',
                    desc: '',
                    benefits: [
                        'Анатомия головы',
                        2000,
                        'Анатомия тела',
                        3000,
                        5000,
                        4000
                    ],
                    count: 2,
                    price: 4000,
                    priceorig: 4000,
                },
                {
                    id:7,
                    name: 'Роспись <br/><span>и парик</span>',
                    desc: 'Как загрунтовать? Как расписать глаза, губы, нарисовать веснушки? Как сделать прямой пробор или челку? Все это в 4-ех часовом МК мнатомии лица.',
                    benefits: [
                        'GZ3ETJJc46s'
                    ],
                    count: 0,
                    price: 3000,
                    priceorig: 3000,
                },
                {
                    id:8,
                    name: 'Костюм XIXв<br/><span>съемный</span>',
                    desc: 'Как сделать выкройку для любой куклы? Как смоделировать? Как сшить платье, панталоны, чулки, воротник и сделать обувь. Все это в 8-часовом МК',
                    benefits: [
                        'unLBRZe17P8'
                    ],
                    count: 0,
                    price: 3500,
                    priceorig: 3500,
                },
                {
                    id:9,
                    name: '',
                    desc: '',
                    benefits: [
                        'Роспись и парик',
                        3000,
                        'Съемный костюм',
                        3500,
                        6500,
                        5000
                    ],
                    count: 2,
                    price: 5000,
                    priceorig: 5000,
                },
                {
                    id:10,
                    name: '',
                    desc: '',
                    benefits: [
                        'Книга по кукле + детская голова + роспись',
                        3000,
                        'Съемный костюм',
                        3500,
                        6500,
                        5000
                    ],
                    count: 0,
                    price: 3800,
                    priceorig: 5950,
                },                {
                    id:11,
                    name: '',
                    desc: '',
                    benefits: [
                        'книга по шарнирке + анатомия тела + анатомия голова + костюм',
                        3000,
                        'Съемный костюм',
                        3500,
                        6500,
                        5000
                    ],
                    count: 0,
                    price: 15000,
                    priceorig: 20000,
                },
                {
                    id:12,
                    name: 'Детская голова',
                    desc: '',
                    benefits: [

                    ],
                    count: 0,
                    price: 950,
                    priceorig: 950,
                },
                {
                    id:13,
                    name: 'Будуарная куклы v2.0',
                    desc: 'Сама',
                    benefits: [

                    ],
                    count: 0,
                    price: 8500,
                    priceorig: 7500,
                },
                {
                    id:14,
                    name: 'Будуарная куклы v2.0',
                    desc: 'С кураторами',
                    benefits: [

                    ],
                    count: 0,
                    price: 11000,
                    priceorig: 10000,
                },
                {
                    id:15,
                    name: 'Будуарная куклы v2.0',
                    desc: 'Вип',
                    benefits: [

                    ],
                    count: 0,
                    price: 30000,
                    priceorig: 35000,
                },
                {
                    id:16,
                    name: 'Будуарная куклы v2.0',
                    desc: 'Сама предоплата',
                    benefits: [

                    ],
                    count: 0,
                    price: 3000,
                    priceorig: 3000,
                },
                {
                    id:17,
                    name: 'Будуарная куклы v2.0',
                    desc: 'Куратор предоплата',
                    benefits: [

                    ],
                    count: 0,
                    price: 3000,
                    priceorig: 3000,
                },
                {
                    id:18,
                    name: 'Будуарная куклы v2.0',
                    desc: 'Вип предоплата',
                    benefits: [

                    ],
                    count: 0,
                    price: 10000,
                    priceorig: 10000,
                },
                {
                    id:19,
                    name: 'Будуарная кукла книга',
                    desc: '',
                    benefits: [

                    ],
                    count: 0,
                    price: 2490,
                    priceorig: 2490,
                },
                {
                    id:20,
                    name: 'Будуарная кукла книга + МК голова',
                    desc: 'Куратор постоплата',
                    benefits: [

                    ],
                    count: 0,
                    price: 3440,
                    priceorig: 3440,
                },
                {
                    id:21,
                    name: 'Будуарная куклы v2.0',
                    desc: 'Вип постоплата',
                    benefits: [

                    ],
                    count: 0,
                    price: 22990,
                    priceorig: 22990,
                },
                {
                    id:22,
                    name: 'Шарнирная кукла',
                    desc: 'Вип постоплата',
                    benefits: [

                    ],
                    count: 0,
                    price: 17500,
                    priceorig: 17500,
                },
                {
                    id:23,
                    name: 'Шарнирная кукла + Анатомия головы + Анатомия тела',
                    desc: '',
                    benefits: [

                    ],
                    count: 0,
                    price: 22500,
                    priceorig: 22500,
                },
                {
                    id:24,
                    name: 'Bjd',
                    desc: '1 step',
                    benefits: [

                    ],
                    count: 0,
                    price: 6000,
                    priceorig: 22500,
                },
                {
                    id:25,
                    name: 'Bjd++',
                    desc: '1 step',
                    benefits: [

                    ],
                    count: 0,
                    price: 8000,
                    priceorig: 22500,
                },
                {
                    id:26,
                    name: 'Bjd course self',
                    desc: '',
                    benefits: [

                    ],
                    count: 0,
                    price: 17500,
                    priceorig: 22500,
                },
                {
                    id:27,
                    name: 'Bjd course self',
                    desc: '1 step',
                    benefits: [

                    ],
                    count: 0,
                    price: 6000,
                    priceorig: 22500,
                },
                {
                    id:28,
                    name: 'Bjd course curators',
                    desc: '',
                    benefits: [

                    ],
                    count: 0,
                    price: 22500,
                    priceorig: 22500,
                },
                {
                    id:29,
                    name: 'Bjd course curators',
                    desc: '1 step',
                    benefits: [

                    ],
                    count: 0,
                    price: 6000,
                    priceorig: 22500,
                },
                {
                    id:30,
                    name: 'Bjd course vip',
                    desc: '',
                    benefits: [

                    ],
                    count: 0,
                    price: 45000,
                    priceorig: 22500,
                },
                {
                    id:31,
                    name: 'Bjd course vip',
                    desc: 'setp 1',
                    benefits: [

                    ],
                    count: 0,
                    price: 12000,
                    priceorig: 12000,
                },
                {
                    id:32,
                    name: 'test',
                    desc: '',
                    benefits: [

                    ],
                    count: 0,
                    price: 10,
                    priceorig: 10,
                }
            ],

            courses: [
                {
                    id: 1,
                    name: 'Анатомия <br><span>головы</span>',
                    cover: '',
                },
                {
                    id: 2,
                    name: 'Анатомия <br><span>тела</span>',
                    cover: '',
                },
                {
                    id: 3,
                    name: 'Роспись <br><span>парик</span>',
                    cover: '',
                },
                {
                    id: 4,
                    name: 'Костюм <br><span>съемный</span>',
                    cover: '',
                },
                {
                    id: 5,
                    name: 'Шарнирная <br><span>кукла</span>',
                    cover: '',
                },
                {
                    id: 6,
                    name: 'Будуарная <br><span>кукла</span>',
                    cover: '',
                },
                {
                    id: 7,
                    name: 'Детская <br><span>голова</span>',
                    cover: '',
                },
                {
                    id: 8,
                    name: 'Будуарная <br><span>кукла</span>',
                    cover: '',
                },
                {
                    id: 9,
                    name: 'Эмоции',
                    cover: '',
                }
            ],
        }
    },
    modules: {
        appStore
    },
    plugins: [appState],
    mutations: {

    }
})

export default store
<template>
  <picture ref="picture" :width="realWidth">
    <source v-if="isPresent" :srcset="getWebP()" type="image/webp" media="(min-width: 240px)">
    <img ref="img" :src="src" :class="[imgClass, $mq]" :alt="alt" :width="realWidth">
  </picture>
</template>

<script>
import {watchEffect} from "vue";

export default {
  data() {
    return {
      realWidth: null
    }
  },
  props: {
    src: String,
    imgClass: String,
    alt: String,
    width: [Number, String]
  },
  name: "KImage",
  computed: {
    isPresent() {
      return process.env.NODE_ENV === 'production' && !this.src.endsWith('.svg')
    }
  },
  created() {
    watchEffect(() => {
      this.$nextTick(() => {
        let width = this.$refs.picture.parentNode.clientWidth
        if (this.width) {
          if (this.width.indexOf('%') >= 0) {
            this.realWidth = Math.floor(width * parseInt(this.width) / 100)
          } else {
            this.realWidth = parseInt(this.width)
          }
        } else {
          return null
        }
      })
    })
  },


  methods: {
    getWebP() {
      return this.src + '.webp'
    },
  }
}
</script>

<style scoped>

</style>
import { log } from "@/service/api"

function videoIframeLoaded(videoId) {

    var iframe = document.getElementById("iframe"+videoId);
    iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 10 + 'px';
}



function openUrl(url) {
    window.open(url)
}

function handleError(error) {
    log('error', error.stack)

    // logger.log('error',error.stack,{userId:userId});
}

function handleMessage(event) {
    if (event.data.href) {
        openUrl(event.data.href)
    }
    if (event.data.error) {
        handleError(event.data.error)
    }
    if (event.data.video_id) {
        videoIframeLoaded(event.data.video_id)
    }
}

function install(Vue, options) {
    if (!window.addEventListener) {
        // IE8 support (could also use an addEventListener shim)
        window.attachEvent('onmessage', handleMessage);
    } else {
        window.addEventListener('message', handleMessage, false);
    }

    window.onerror = function (msg, url, lineNo, columnNo, error) {
        if (error.stack.indexOf('$metrika') === -1) {
            handleError(error)
        }
    }
}

export default install
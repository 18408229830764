<template>
    <svg xmlns="http://www.w3.org/2000/svg" 
         :width="width"
         :height="height"
         viewBox="0 0 97.89 97.89"
    >
        <g data-name="Layer 2" :style="getStyle">
            <g data-name="Layer 1" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="5">
                <path d="M2.5 2.5l40.47 28.47L85.26 2.5"/>
                <path opacity=".5" d="M2.5 23.95l40.47 28.47 42.29-28.47"/>
                <path opacity=".15" d="M2.5 45.41l40.47 28.47 42.29-28.47"/>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "KIDown",
        props: {
            width: {
                type: [Number, String],
                default: 50
            },
            height: {
                type: [Number, String],
                default: 50
            },
            iconColor: {
                type: String,
                default: '#ffffff'
            }
        },
        computed: {
            getStyle () {
                return 'fill: '+this.iconColor
            }
        }
    }
</script>
